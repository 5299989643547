import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AnalyticsService } from '../core/services/google-analytics/analytics.service';

@Component({
  selector: 'msn-file-a-claim',
  templateUrl: './file-a-claim.component.html',
  styleUrls: ['./file-a-claim.component.scss']
})
export class FileAClaimComponent  implements OnInit{
  subscriptionTranslate!: Subscription;

  lang!: string ;
  constructor(     
    private analyticsService: AnalyticsService,
    private translateService: TranslateService
  ) { }
  ngOnInit() {
    this.lang = this.translateService.currentLang;
    // this.analyticsService.trackEvent('page_view', 'file-a-claim', 'file_a_claim_page_view');
    this.subscriptionTranslate = this.translateService.onLangChange.subscribe(
      (lang) => {
        this.lang = lang.lang;
      });
  }
}
