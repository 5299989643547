import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AnalyticsService } from '../../services';


@Component({
  selector: 'msn-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss']
})
export class DownloadAppComponent implements OnInit  {

  isMobile: boolean;
  device: any;
  os: any;

  constructor(private deviceService: DeviceDetectorService,  
    private analyticsService: AnalyticsService,

    private router: Router,
  ) {
    this.isMobile = this.deviceService.isMobile();
  }
  ngOnInit(): void {
    ;
    // this.analyticsService.trackEvent('page_view', 'download-app', 'download_app_page_view');
    const isMobile = this.deviceService.isMobile();
    console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    const isTablet = this.deviceService.isTablet();
    console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    const isDesktopDevice = this.deviceService.isDesktop();
    console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
    const deviceInfo = this.deviceService.getDeviceInfo();
    console.log(deviceInfo);  // returns object with all device info
    this.device = this.deviceService.device;
    console.log(this.device); // returns device name
    const deviceType = this.deviceService.deviceType;
    console.log(deviceType); // returns device type
    this. os = this.deviceService.os;
    console.log(this.os); // returns OS
    const os_version = this.deviceService.os_version;
    console.log(os_version); // returns OS version
    const browser = this.deviceService.browser;
    console.log(browser); // returns browser
    const browser_version = this.deviceService.browser_version;
    console.log(browser_version); // returns browser version
    if(this.os === 'iOS'){
      this.openAppStore();
    }else if(this.os === 'Android'){
      this.openPlayStore();
    }
    else{
      this.openPlayStore();
    }
    this.router.navigate(['/home']);


  }

  openAppStore() {
    window.open('https://apps.apple.com/sa/app/extra-services/id1459789218', '_blank');
  }

  openPlayStore() {
    window.open('https://play.google.com/store/apps/details?id=com.smarttechsys.extra&pli=1', '_blank');
  }

}
