import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { Subscription } from 'rxjs';
import { PageTitleService } from '../../services/page-title.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language';
import { AnalyticsService, AuthService } from '../../services';
import { LogINInfo } from '../../models/login-info';
import { Router } from '@angular/router';
import { CurrentUserService } from '../../services/auth/currentuser.service';
import { Location } from '@angular/common';


@Component({
  selector: 'msn-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy{
  currentYear = new Date().getFullYear();
  language!: string;
  subscriptionTranslate!: Subscription;

  subscriptions: Subscription[] = [];
  phoneForm!: FormGroup;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.SaudiArabia];
  phone!: string;
  otpForm!: FormGroup;
  @ViewChild('otpModal')
  otpModal!: ElementRef;

  constructor(
    private fb: FormBuilder, 
    private pageTitle: PageTitleService,
    public languageService: LanguageService,
    private translateService: TranslateService,
    private authService: AuthService,
    private currentuserService: CurrentUserService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private location: Location

  ) {
  }

  ngOnInit(): void {
    this.setPageTitle();
    ///this.setLanguageSubscriber();
    // this.analyticsService.trackEvent('page_view', 'login', 'login_page_view');
    this.phoneForm = this.fb.group({
      phone: ['', [Validators.required,Validators.pattern("^(5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$")]]
    });
    this.otpForm = this.fb.group({
      code: ['', [Validators.required]]
    });
    this.phoneForm.statusChanges.subscribe((status) => {
      if (this.phoneForm.valid) {
        document.getElementById('submitbtn')?.removeAttribute('disabled');

      }else{
        document.getElementById('submitbtn')?.setAttribute('disabled','true');
      }
    });
    this.otpForm.statusChanges.subscribe((status) => {
      if (this.otpForm.valid) {
        document.getElementById('otbsubmitbtn')?.removeAttribute('disabled');

      }else{
        document.getElementById('otbsubmitbtn')?.setAttribute('disabled','true');
      }
    });
  }
  
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setLanguageSubscriber(): void {
    this.language = this.translateService.currentLang;
    this.subscriptionTranslate = this.translateService.onLangChange.subscribe(
      val => {
        this.language = val.lang;
      },
      error => {},
      () => {}
    );
    this.subscriptions.push(this.subscriptionTranslate);
  }
  openLoader(){
    const element = document.getElementById('openModalloader') as HTMLElement;
       
    element.click()
  }
  closeLoader(){
    const element = document.getElementById('closeModalloader') as HTMLElement;
       
    element.click()
  }
  submit(){
    //replace + with 00
    if(this.phoneForm.valid){
      ;
    this.openLoader();
    this.phone=this.phoneForm.controls["phone"].value;
    if(this.phone.startsWith("+966") ){
      this.phone=this.phone.replace("+966","00966");
    }else if(this.phone.startsWith("5")){
      this.phone="00966"+this.phone;
    }else if (this.phone.startsWith("05")){
      this.phone=this.phone.substring(1);
      this.phone="00966"+this.phone;

    }else if(this.phone.startsWith("966")){
      this.phone="00"+this.phone;
    }
    // this.phone=this.phone.replace("+","00");
    this.subscriptions.push(this.authService.SendCustomerCode(this.phone).subscribe(
      (res: any)=>{
        this.closeLoader();
        if(res != null){
          this.analyticsService.trackEvent('otp_sent', 'login', `otp_sent for ${this.phone}`);
          const element = document.getElementById('openModal') as HTMLElement;
       
        element.click()
      }
      }
    ));
    }else{
      this.phoneForm.markAllAsTouched();
    
    }
  }
  getControlphone(name: string) {
    return this.phoneForm.controls[name];
  }
  getControlotp(name: string) {
    return this.otpForm.controls[name];
  }
  VerifyOTP(){
    if(this.otpForm.valid){

    const loginInfo : LogINInfo = {
      mobileNo: this.phone,
      code: this.otpForm.controls["code"].value
      };
      // this.openLoader();

    this.subscriptions.push( this.authService.login(loginInfo).subscribe(res=>{
      // console.log(res);
      if(res != null){
        // this.closeLoader();
        this.analyticsService.trackEvent('Confirm_OTP', 'login', `Confirm_OTP for ${this.phone}`);

        const element = document.getElementById('closeModal') as HTMLElement;
       
        element.click()
      localStorage.setItem('currentUser', JSON.stringify(res));
      this.currentuserService.login(res);
      if(!res.isEmailVerification){
        this.router.navigate(['signup']);
      }
      else{
        this.location.historyGo(-1);
        this.router.navigate(['/home']);
        
      }
    }
    else{
      
      this.otpForm.controls["code"].setErrors({invalidCode: true});
      this.otpForm.markAllAsTouched();

    }
    }));
  }else{
    this.otpForm.markAllAsTouched();
  }
  }


  setPageTitle(): void {
    this.pageTitle.setTitleTranslated(`shared.login`);
  }
}


